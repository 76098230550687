import React from "react"

import { Layout } from "../../components/Layout"
import { Form } from "../../components/email/Form"

const seoData = {
  title: "Подарок за адрес электронной почты, карта Халва Совкомбанк",
}

const phones = ["8-800-100-777-2"]

export default function EmailPage() {
  return (
    <Layout seoData={seoData} phones={phones} headerProps={{ PhoneProps: { phones } }}>
      <Form />
    </Layout>
  )
}
